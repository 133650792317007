.a-app-content-container:has(.a-main-menu-container.a-collapsed) .a-grid-page-container.a-smart-grid-menu .a-smart-grid-container {
  left: 4.9rem;
  transition: left 0.2s ease;
}

.a-app-content-container:has(.a-main-menu-container) .a-grid-page-container.a-smart-grid-menu .a-smart-grid-container {
  top: 4rem;
  left: 250px;
  height: calc(100% - 4rem);
  transition: left 0.2s ease;
}

.a-grid-page-container.a-smart-grid-menu .a-smart-grid-container {
  position: absolute;
  width: var(--grid-navigation-width, 250px);
  border-radius: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  top: 0;
  left: 0;
  height: 100%;
}
.a-grid-page-container.a-smart-grid-menu .a-smart-grid-container .a-smart-grid-search-container {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .a-grid-page-container.a-smart-grid-menu .a-smart-grid-container {
    display: none;
  }
}
.a-grid-page-container.a-smart-grid-menu .a-smart-grid-container .p-datatable-thead > tr > th.a-smart-grid-first-column-header {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 3.6rem;
}
.a-grid-page-container.a-smart-grid-menu .a-smart-grid-container .p-datatable-thead > tr > th.a-smart-grid-first-column-header .a-smart-grid-insert-column-header-button {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.a-grid-page-container.a-smart-grid-menu .a-smart-grid-container .p-datatable-thead > tr > th.a-smart-grid-first-column-header .a-smart-grid-insert-column-header-button .a-smart-button {
  padding: 0.3rem 0.3rem;
  min-width: unset;
}

.a-grid-page-container:not(.a-smart-grid-menu) .p-datatable .p-datatable-tbody > tr > td:not(.p-selection-column, .p-reorder-column, .a-smart-grid-extendable-column),
.a-grid-page-container:not(.a-smart-grid-menu) .p-datatable .p-datatable-thead > tr > th:not(.p-selection-column, .p-reorder-column, .a-smart-grid-extendable-column),
.a-smart-popup-grid.a-stretch .p-datatable .p-datatable-tbody > tr > td:not(.p-selection-column, .p-reorder-column, .a-smart-grid-extendable-column),
.a-smart-popup-grid.a-stretch .p-datatable .p-datatable-thead > tr > th:not(.p-selection-column, .p-reorder-column, .a-smart-grid-extendable-column) {
  width: 160px;
  min-width: 160px;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-row-expansion {
  background: #303030;
}
.p-datatable .p-datatable-tbody > tr > td:not(.p-selection-column, .a-smart-grid-extendable-column).a-right,
.p-datatable .p-datatable-thead > tr > th:not(.p-selection-column, .a-smart-grid-extendable-column).a-right {
  text-align: right;
}
.p-datatable th:not(.a-smart-grid-checkbox-column) .p-column-title {
  width: 100%;
  overflow: hidden;
}
.p-datatable .p-column-filter-menu {
  overflow: visible;
}
.p-datatable .p-column-filter-menu-button {
  width: 1.8rem;
  height: 1.8rem;
}
.p-datatable .p-column-filter-menu-button .pi {
  line-height: unset;
}

.a-smart-grid-container {
  height: 100%;
  background: #292929;
  border: 1px solid #616161;
  border-radius: 8px;
  overflow: auto;
}
.a-smart-grid-container .p-datatable-resizable .p-datatable-thead > tr > th,
.a-smart-grid-container .p-datatable-resizable .p-datatable-tfoot > tr > td,
.a-smart-grid-container .p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: visible;
  white-space: unset;
}
.a-smart-grid-container .p-column-resizer-helper {
  max-height: 100%;
}
.a-smart-grid-container .a-smart-grid-selection-actions {
  display: none;
  position: absolute;
  left: 0.9rem;
  height: 2.4rem;
  border: 1px solid #616161;
  z-index: 1;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container {
  border-right: 1px solid #616161;
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container:last-child {
  border: none;
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container .a-smart-grid-selection-action {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.6rem;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container .a-smart-grid-selection-action.a-smart-grid-selection-selected {
  padding-left: 4.1rem;
  padding-right: 0;
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container .a-smart-grid-selection-action.a-smart-grid-selection-selected .a-smart-grid-selection-selected-cancel {
  padding: 0.6rem;
  z-index: 100;
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container .a-smart-grid-selection-action:hover:not(.a-disabled) {
  background: rgba(255, 255, 255, 0.03);
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container .a-smart-grid-selection-action.a-disabled {
  cursor: default;
  opacity: 0.15;
}
.a-smart-grid-container .a-smart-grid-selection-actions .a-smart-grid-selection-action-container .a-smart-grid-selection-more-action svg {
  margin-left: 0.6rem;
}
.a-smart-grid-container .a-smart-grid-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.a-smart-grid-container .a-smart-grid-header .a-smart-grid-search-container {
  width: 33%;
}
.a-smart-grid-container .a-smart-grid-header .a-smart-grid-header-actions {
  display: flex;
  justify-content: flex-end;
  width: 60%;
}
.a-smart-grid-container .a-smart-grid-header .a-smart-grid-header-actions .a-smart-grid-header-selector {
  width: 100%;
  max-width: 20rem;
  margin: 0 0.3rem;
}
.a-smart-grid-container .a-smart-grid-header .a-smart-grid-header-actions .a-smart-grid-header-selector:first-child {
  margin-left: 0;
}
.a-smart-grid-container .a-smart-grid-header .a-smart-grid-header-actions .a-smart-grid-header-selector:last-child {
  margin-right: 0;
}
.a-smart-grid-container .a-smart-grid-footer {
  font-size: 0.8rem;
  text-align: right;
}
.a-smart-grid-container:not(.a-virtual-render) .p-datatable-wrapper .p-virtualscroller .p-datatable-tbody > tr:not(.p-datatable-emptymessage):not(.p-datatable-row-expansion) > td {
  padding: 0 1rem;
}
.a-smart-grid-container .p-datatable-wrapper {
  overflow: auto;
  flex: 1;
}
.a-smart-grid-container .p-datatable-wrapper .p-virtualscroller {
  height: 100% !important;
}
.a-smart-grid-container .p-datatable-wrapper table thead {
  background: #292929;
  z-index: 1;
}
.a-smart-grid-container .p-datatable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.a-smart-grid-container .p-datatable .a-smart-grid-highlight .pi-filter-icon {
  color: #fd0;
}
.a-smart-grid-container .p-datatable .p-sortable-column.p-highlight,
.a-smart-grid-container .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  color: #ffffff;
}
.a-smart-grid-container .p-datatable .a-smart-grid-icon-info {
  display: flex;
  align-items: center;
}
.a-smart-grid-container .p-datatable .a-smart-grid-icon-info svg {
  margin-right: 8px;
}
.a-smart-grid-container .p-datatable .p-datatable-header {
  padding: 0;
  border: 0;
  z-index: 1;
}
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr, .a-smart-grid-container .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  color: #ffffff;
}
.a-smart-grid-container .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover,
.a-smart-grid-container .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover, .a-smart-grid-container .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #303030;
}
.a-smart-grid-container .p-datatable .p-sortable-column .p-sortable-column-icon {
  margin: 0 0.3rem;
  overflow: visible;
}
.a-smart-grid-container .p-datatable .p-sortable-column .p-sortable-column-badge {
  display: none;
}
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr.a-processed, .a-smart-grid-container .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr.a-processed:not(.p-highlight):hover {
  color: #616161;
}
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr.a-disabled td {
  text-decoration: line-through;
}
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr > td .a-smart-grid-inline-data-value,
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr > td .a-smart-grid-default-template {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
}
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr > td .a-smart-grid-inline-data-value:not(.a-no-wrap),
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr > td .a-smart-grid-default-template:not(.a-no-wrap) {
  white-space: break-spaces;
}
.a-smart-grid-container .p-datatable .p-datatable-tbody > tr:last-child > td {
  border: 0;
}
.a-smart-grid-container .p-datatable .p-reorder-column {
  width: 3rem;
}
.a-smart-grid-container .p-datatable .p-reorder-column .p-datatable-reorderablerow-handle {
  cursor: grab;
}
.a-smart-grid-container .p-datatable .a-smart-grid-extendable-column,
.a-smart-grid-container .p-datatable .p-selection-column {
  width: 4rem !important;
  padding-left: 1.5rem !important;
}
.a-smart-grid-container .p-datatable .a-smart-grid-extendable-column .p-checkbox,
.a-smart-grid-container .p-datatable .a-smart-grid-extendable-column .p-checkbox-box,
.a-smart-grid-container .p-datatable .p-selection-column .p-checkbox,
.a-smart-grid-container .p-datatable .p-selection-column .p-checkbox-box {
  width: 1.5rem;
  height: 1.5rem;
}
.a-smart-grid-container .a-smart-grid-inline-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 2px 0;
}
.a-smart-grid-container .a-smart-grid-inline-data:empty {
  display: none;
}
.a-smart-grid-container .a-smart-grid-inline-data:first-child {
  padding-top: 0;
}
.a-smart-grid-container .a-smart-grid-inline-data:last-child {
  padding-bottom: 0;
}
.a-smart-grid-container .a-smart-grid-inline-data .a-smart-grid-inline-data-label {
  color: #a8a8a8;
  padding-right: 0.3rem;
  white-space: nowrap;
}
.a-smart-grid-container .a-smart-grid-inline-data .a-smart-grid-inline-data-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.a-smart-grid-container .a-smart-grid-inline-data .a-form-element-value {
  flex: 1;
}
.a-smart-grid-container .a-smart-grid-flag {
  padding-top: 2px;
}

.a-smart-grid-search-container {
  display: flex;
  gap: 0.6rem;
}

.a-smart-grid-menu-content {
  position: relative;
  width: calc(100% - 250px);
}
.a-smart-grid-menu-content .a-smart-history-list {
  margin-top: 1.2rem;
}
@media screen and (max-width: 767px) {
  .a-smart-grid-menu-content {
    width: 100%;
  }
}

.a-smart-grid-column-header-filter-icon {
  color: #fd0;
  padding: 0 0.3rem;
}

.a-smart-grid-copy-to-clipboard-column {
  display: flex;
  border: 1px solid transparent;
  border-radius: 4px;
}
.a-smart-grid-copy-to-clipboard-column .a-smart-grid-copy-to-clipboard-value,
.a-smart-grid-copy-to-clipboard-column .a-smart-grid-copy-to-clipboard {
  padding: 0.5rem;
  word-break: break-all;
}
.a-smart-grid-copy-to-clipboard-column .a-smart-grid-copy-to-clipboard {
  display: flex;
  align-items: center;
  border-left: 1px solid #616161;
  user-select: none;
  cursor: pointer;
}
.a-smart-grid-copy-to-clipboard-column .a-smart-grid-copy-to-clipboard:hover {
  background: rgba(255, 255, 255, 0.03);
}
.a-smart-grid-copy-to-clipboard-column:hover {
  border: 1px solid #616161;
}
.a-smart-grid-copy-to-clipboard-column:hover .a-smart-grid-copy-to-clipboard.a-invisible {
  visibility: visible;
}

.a-smart-grid-active-actions-header {
  position: relative;
}
.a-smart-grid-active-actions-header tr:first-child > th:not(.p-selection-column) {
  pointer-events: none;
}
.a-smart-grid-active-actions-header tr:first-child > th:not(.p-selection-column) * {
  pointer-events: none;
  opacity: 0;
}
.a-smart-grid-active-actions-header .a-smart-grid-selection-actions {
  display: flex;
}

.a-smart-grid-checkbox-column {
  position: relative;
  overflow: visible;
}
.a-smart-grid-checkbox-column .a-smart-grid-selection-header-spinner {
  color: #fd0;
  opacity: 1;
  font-size: 1.4rem;
}

.a-smart-grid-multiselect-filter-container.p-column-filter-overlay {
  border: 0;
}
.a-smart-grid-multiselect-filter-container.p-column-filter-overlay .p-column-filter-matchmode-dropdown,
.a-smart-grid-multiselect-filter-container.p-column-filter-overlay .p-column-filter-buttonbar {
  display: none;
}
.a-smart-grid-multiselect-filter-container.p-column-filter-overlay .p-column-filter-constraint {
  padding: 0;
}

.a-smart-grid-multiselect-filter-panel .p-multiselect-close {
  display: none;
}

.a-smart-grid-overflow .p-datatable-table {
  border-spacing: 0 1px;
}
.a-smart-grid-overflow thead {
  position: sticky;
  top: 0;
}
.a-smart-grid-overflow.a-flex {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.a-smart-grid-overflow .p-datatable .p-datatable-thead > tr > th,
.a-smart-grid-overflow .p-datatable .p-datatable-tbody > tr > td {
  width: 100%;
}

.a-loading-spinner {
  color: #fd0;
  font-size: 4rem;
}

.a-smart-filter-relative-calendar {
  display: flex;
  gap: 0.6rem;
}
.a-smart-filter-relative-calendar .a-form-element-value:has(.p-inputnumber) {
  width: 25%;
  max-width: 25%;
}
.a-smart-filter-relative-calendar .a-form-element-value:has(.p-dropdown) {
  width: 37.5%;
  max-width: 37.5%;
}

.a-smart-filter-calendar-container .p-calendar {
  width: 100%;
}

.p-column-filter-overlay:has(.a-smart-filter-calendar-container) {
  min-width: 300px;
}

.a-section-inline-subline .a-inline-subline {
  padding-left: 0.6rem;
}

.a-smart-grid-icon-flag-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.a-smart-grid-icon-flag-container .a-smart-grid-icon-flag {
  color: #fd0;
}
.a-smart-grid-icon-flag-container .a-smart-grid-icon-flag:hover {
  opacity: 0.8;
  cursor: help;
}

.a-smart-grid-filter-apply-button {
  width: 40%;
  max-width: 100px;
}
.a-smart-grid-filter-apply-button .a-smart-button {
  width: 100%;
}

