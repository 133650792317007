@import './variables';

.a-form-element-content,
.a-form-element-paginator-container {
  &:not(:last-child) {
    border-bottom: 1px solid a-get-color(primary, bright);
  }
}

.a-form-element-paginator-container {
  .p-paginator {
    border: none;
    background: none;
    padding-top: 0;
  }
}

.a-form-element-container {
  width: 100%;
  background: a-get-color(primary, darkest);
  border: 1px solid a-get-color(primary, bright);
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;

  .a-form-element-header {
    padding: 1.2rem 1.2rem 0 1.2rem;

    &.a-flex-center {
      justify-content: space-between;
    }
  }

  .a-form-element-content {
    padding: 1.2rem;

    &:has(+ .a-form-element-footer) {
      border-bottom: none;
    }

    .a-form-element-header {
      font-weight: a-get-font-weight(normal);
      font-size: a-get-font-size(m);
      text-transform: uppercase;
      padding: 0 0 0.6rem 0;

      .a-smart-button {
        text-transform: none;
      }
    }

    &.a-flex-center {
      justify-content: space-between;
    }

    .a-form-element-column-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .a-form-element-column {
        width: 47%;
      }
    }

    >div:last-of-type {
      padding-bottom: 0;
    }

    >div:first-of-type {
      padding-top: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.a-form-element-header {
  font-weight: a-get-font-weight(semibold);
  font-size: a-get-font-size(xl);
  color: a-get-color(basic, bright);

  .a-smart-button {
    font-size: a-get-font-size(m);
  }
}

.a-form-element-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.6rem;
  padding: 0 1.2rem 1.2rem 1.2rem;
}

.a-form-element-input-section {
  width: 100%;
  padding: 0.6rem 0;

  label.a-form-element-label {
    display: block;
  }

  .a-form-element-label {
    padding-bottom: 0.3rem;

    &.a-extra-padding {
      padding-bottom: 0.6rem;
    }
  }

  .a-form-element-sub-label {
    padding: 0.3rem 0 0.6rem 0;
  }

  &.a-flex,
  &.a-flex-center,
  &.a-flex-end {
    justify-content: space-between;

    .a-form-element-label {
      display: flex;
      align-items: center;
      padding-bottom: 0;
    }

    &>* {
      width: 47%;
    }
  }

  .a-form-element-multiple-input-row {
    padding: 0;
  }
}

.a-form-element-label {
  font-weight: a-get-font-weight(semibold);
  width: 100%;

  &:not(.a-red) {
    color: a-get-color(basic, bright);
  }

  &.a-light {
    font-weight: a-get-font-weight(normal);
  }

  &.a-disabled {
    opacity: 0.4;
  }
}

.a-form-element-multiple-input-row {
  display: flex;
  align-items: center;
  padding: 0.6rem 0;

  .a-form-element-multiple-input-row {
    padding: 0;
  }

  .a-form-element-input-section {
    padding: 0 0.6rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.a-right-input .p-inputnumber input {
  text-align: right;
}

.a-center-input .p-inputnumber input {
  text-align: center;
}

a.a-form-element-value.a-readonly {
  display: block;
}

.a-form-element-value {
  --inline-max-length-width: 4rem;

  position: relative;
  width: 100%;

  &:has(.a-form-element-max-length-inline:not(.a-hidden)) {
    .p-inputtext {
      padding-right: var(--inline-max-length-width);
    }
  }

  .a-form-element-max-length-inline {
    position: absolute;
    width: var(--inline-max-length-width);
    text-align: right;
    height: 100%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .a-form-element-top-right-section {
    position: absolute;
    right: 2px;
    top: -1.5rem;
    display: flex;

    .a-voice-recognition {
      color: a-get-color(secondary, normal);
      font-size: a-get-font-size(l);
      padding: 0 0.4rem;
      cursor: pointer;
    }

    .a-recording {
      color: a-get-color(red, normal);
    }
  }

  .a-form-element-max-length {
    font-size: a-get-font-size(s);
  }

  input,
  textarea,
  .p-inputnumber,
  .a-smart-dropdown-wrapper,
  .p-dropdown,
  .p-calendar,
  .p-multiselect {
    width: 100%;
  }

  &.a-required input,
  &.a-required textarea,
  &.a-required .p-dropdown,
  &.a-required .p-multiselect {
    border-left: 4px solid a-get-color(red, normal);

    &:hover,
    &:focus,
    &.p-focus {
      border-left: 4px solid a-get-color(secondary, normal);
    }
  }

  &.a-readonly,
  &>.a-readonly {
    font-size: a-get-font-size(l);
    height: 2.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    width: auto;

    &.a-form-element-textarea {
      overflow: auto;
      white-space: pre-wrap;
    }
  }

  &.a-right {
    justify-content: flex-end;
  }

  &.a-form-element-textarea {
    --h: calc(var(--height-factor, 1) * 5rem);

    &[data-is-min-height="true"] {
      min-height: min(5rem, var(--h));
    }

    &[data-is-min-height="false"] {
      min-height: 2.5rem;
    }

    max-height: var(--h);
    height: 100%;
    width: 100%;

    &:has(textarea) {
      height: var(--h);
    }

    textarea {
      overflow: auto !important;
      height: 100% !important;
    }
  }

  &.a-no-height-limit {
    max-height: none;
  }

  &.a-inline-input {
    display: flex;

    .a-smart-toggle {
      height: auto;
    }

    &.a-required {

      input,
      textarea,
      .p-dropdown {
        border: 1px solid a-get-color(primary, bright);
        border-right: 0;
        border-top: 0;
        border-left: 4px solid a-get-color(red, normal);
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        text-indent: 0.3rem;
      }
    }

    input,
    textarea,
    .p-dropdown {
      border: 0;
      border-bottom: 1px solid a-get-color(primary, bright);
      border-radius: 0;
      background: transparent;
      text-indent: unset;
      padding: 0;

      &.p-inputtext:enabled:focus {
        box-shadow: none;
        border-bottom: 1px solid a-get-color(yellow, bright);
      }
    }

    .p-dropdown .p-inputtext {
      padding: 0;
      padding-right: 0.9rem;
    }

    .p-button.p-button-icon-only {
      padding: 0;
    }

    .p-dropdown-trigger {
      display: none;
    }

    .p-dropdown-clear-icon {
      right: 0;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: none;
    }
  }

  input[type="color"] {
    padding: 0;
    border: none;
    appearance: none;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
  }
}

.a-inline-label-border {
  border-bottom: 1px solid transparent;
}

.a-form-element-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.2rem;
}

.a-form-element-preferred-item {
  display: flex;
  align-items: center;

  svg {
    color: a-get-color(secondary, normal);
    margin-right: 0.6rem;
    font-size: a-get-font-size(s);
  }
}

.a-form-inline-action-button {
  color: a-get-color(secondary, normal);
  text-transform: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: a-get-color(secondary, bright);
    text-decoration: underline;
  }

  &.a-disabled {
    opacity: 0.4;
    cursor: default;
    color: #d5d5d5;

    &:hover {
      text-decoration: none;
    }
  }
}