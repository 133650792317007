@import '../../../styles/base/variables';


.a-smart-grid-input {
  display: flex;
  gap: 0.6rem;
  justify-content: space-between;
  padding: 0.3rem;

  button {
    all: unset;

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  .a-smart-grid-input-readonly-value {
    width: 100%;
  }

  &[data-type="text"] {
    .a-smart-grid-input-readonly-value {
      border-bottom: 1px solid a-get-color(primary, darkest);
    }

    &[data-is-edit="false"]:hover {
      .a-smart-grid-input-readonly-value {
        border-bottom: 1px solid a-get-color(yellow, normal);
      }
    }
  }

  .a-smart-grid-input-actions-container {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    z-index: 1000;
  }

  .a-smart-grid-input-edit-button {
    color: a-get-color(basic, normal);
  }

  &[data-is-edit="false"]:not(:disabled):hover {
    .a-smart-grid-input-edit-button {
      color: a-get-color(basic, brightest);
    }

    background: a-get-color(hover, normal);
    border-radius: 0.3rem;
  }

  .a-smart-grid-input-action-button {
    padding: 0.15rem 0.3rem;
    border-radius: 0.3rem;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    transition: all 0.2s ease;
  }
}