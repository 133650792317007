@font-face {
  font-family: "OpenSans";
  src: url("https://alix-static-files.s3.ca-central-1.amazonaws.com/fonts/OpenSans-Regular.woff") format("woff");
}
@font-face {
  font-family: "Segoe UI";
  src: url("https://alix-static-files.s3.ca-central-1.amazonaws.com/fonts/Segoe+UI.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #535353;
  border-radius: 4px;
}

html,
body,
#root {
  --smart-pin-button-height: 2.4rem;
  overflow: hidden;
  background: #363636;
  color: #ffffff;
  font-family: "Segoe UI", "OpenSans";
  font-size: 14px;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
html:has(.a-app-content-container.a-responsive-font),
body:has(.a-app-content-container.a-responsive-font),
#root:has(.a-app-content-container.a-responsive-font) {
  font-size: 9.8px;
}
@media screen and (min-width: 768px) {
  html:has(.a-app-content-container.a-responsive-font),
  body:has(.a-app-content-container.a-responsive-font),
  #root:has(.a-app-content-container.a-responsive-font) {
    font-size: 11.2px;
  }
}
@media screen and (min-width: 992px) {
  html:has(.a-app-content-container.a-responsive-font),
  body:has(.a-app-content-container.a-responsive-font),
  #root:has(.a-app-content-container.a-responsive-font) {
    font-size: 14px;
  }
}

.a-app-container {
  --a-app-header-height: 4rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.a-app-content-container {
  display: flex;
  flex: 1;
  overflow: auto;
}

.a-app-content {
  flex: 1;
  overflow: auto;
}
.a-app-content .a-smart-messages {
  top: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  width: auto;
  z-index: 99;
}
.a-app-content .a-smart-messages div {
  margin: 0;
}
.a-app-content .a-smart-messages svg {
  font-size: 1.2rem;
  padding-right: 0.3rem;
}
.a-app-content .a-smart-messages .p-message-wrapper {
  min-height: 4rem;
}
@media screen and (min-width: 768px) {
  .a-app-content:has(.a-grid-page-wrapper.a-wrapped) .a-smart-messages {
    --grid-navigation-width: 250px;
    --content-container-width: calc(100% - var(--grid-navigation-width) + 2 * var(--default-padding));
    width: var(--content-container-width);
    left: var(--grid-navigation-width);
  }
  .a-app-content:has(.a-grid-page-wrapper.a-wrapped.a-app-flush-content) .a-smart-messages {
    --default-padding: 0px;
  }
}
.a-app-content:has(.a-front-iframe-wrapper.a-hidden):not(:has(iframe.a-dashboard-iframe)), .a-app-content:not(:has(.a-front-iframe-wrapper)) {
  --default-padding: 1.2rem;
}
.a-app-content:has(.a-front-iframe-wrapper.a-hidden):not(:has(iframe.a-dashboard-iframe)) .a-smart-messages, .a-app-content:not(:has(.a-front-iframe-wrapper)) .a-smart-messages {
  top: calc(-1 * var(--default-padding));
  margin-left: calc(-1 * var(--default-padding));
  margin-right: calc(-1 * var(--default-padding));
}
.a-app-content:has(.a-front-iframe-wrapper:not(.a-hidden)) {
  display: flex;
  flex-direction: column;
}
.a-app-content:not(:has(.a-app-flush-content)) {
  padding: var(--default-padding);
}

iframe.a-front-iframe {
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

a,
a:active,
a:hover,
a:visited {
  color: #ffffff;
  text-decoration: none;
}

.a-flex {
  display: flex;
}
.a-flex.a-stretch {
  flex: 1;
}
.a-flex.a-column {
  flex-direction: column;
}
.a-flex.a-align-center {
  align-items: center;
}
.a-flex.a-justify-center {
  justify-content: center;
}
.a-flex.a-justify-between {
  justify-content: space-between;
}
.a-flex.a-justify-end {
  justify-content: flex-end;
}
.a-flex.a-gap {
  gap: 0.6rem;
}
.a-flex.a-wrap {
  flex-wrap: wrap;
}

.a-flex-column {
  display: flex;
  flex-direction: column;
}

.a-flex-center {
  display: flex;
  align-items: center;
}
.a-flex-center.a-gap {
  gap: 0.6rem;
}

.a-flex-end {
  display: flex;
  align-items: flex-end;
}

.a-justify-center {
  display: flex;
  justify-content: center;
}

.a-no-focus:focus {
  outline: none;
}

.a-hidden {
  display: none !important;
}

.a-green {
  color: #0f960f;
}

.a-red {
  color: #ff4949;
}

.a-secondary {
  color: #fd0;
}

.a-white {
  color: #ffffff;
}

.a-darkest {
  color: #292929;
}

.a-yellow {
  color: #fd0;
}

.a-semi-bold {
  font-weight: 600;
}

.a-trash-button {
  color: #ff4949;
  cursor: pointer;
}

.a-default-value {
  color: #a8a8a8;
}

.a-pre-wrap {
  word-break: break-word;
  white-space: pre-wrap;
}
.a-pre-wrap.a-before:before {
  content: "\a";
}
.a-pre-wrap.a-after:after {
  content: "\a";
}

.a-no-wrap {
  white-space: nowrap;
}

.p-inputnumber-button-up.p-button,
.p-inputnumber-button-down.p-button {
  background: #292929;
  color: #fd0;
  border-color: #616161;
}
.p-inputnumber-button-up.p-button:not(a):not(.p-disabled):hover,
.p-inputnumber-button-down.p-button:not(a):not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.03);
  color: #fd0;
  border-color: #616161;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-button-up {
  border-left: 0;
}
.p-inputnumber-buttons-horizontal .p-inputnumber-button-down {
  border-right: 0;
}

.a-custom-toast-content {
  display: flex;
  width: 100%;
}

.a-spin {
  -webkit-animation: fa-spin 1s infinite cubic-bezier(0.22, 0.61, 0.28, 0.94);
  animation: fa-spin 1s infinite cubic-bezier(0.22, 0.61, 0.28, 0.94);
}

.a-grid-page-wrapper {
  --grid-navigation-width: 250px;
  --content-container-width: calc(100% - var(--grid-navigation-width));
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.a-grid-page-wrapper.a-wrapped {
  height: fit-content;
  min-height: 100%;
}

.a-grid-page-content-container {
  width: var(--content-container-width);
  height: 100%;
  overflow: auto;
}
.a-grid-page-content-container .a-page-header-container {
  margin: 0;
  width: auto;
}
.a-grid-page-content-container .a-smart-grid-menu-content {
  margin: var(--default-padding);
}
.a-grid-page-content-container .a-tare-container .a-tare-content-container {
  margin-top: 0;
}

.a-grid-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.a-grid-page-container:not(.a-smart-grid-menu) {
  width: 100%;
}
.a-grid-page-container .p-datatable-tbody tr:not(.p-datatable-emptymessage):not(.a-row-disabled) {
  cursor: pointer;
}

.p-toast-detail .a-toast-detail-link {
  color: inherit;
  text-decoration: underline;
}

.a-clickable-link {
  cursor: pointer;
  text-decoration: underline;
}
.a-clickable-link.a-required {
  color: #ff4949;
}
.a-clickable-link:hover {
  color: #fd0;
}

.a-smart-menu-overlay {
  position: fixed;
  top: 4rem;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
}

.a-navigation-link {
  cursor: pointer;
  color: #20a1e7 !important;
}

.a-measure-clickable-link:not(.a-measure-clickable-link-label) {
  cursor: pointer;
  text-decoration: underline;
}
.a-measure-clickable-link:not(.a-measure-clickable-link-label):hover {
  opacity: 0.7;
}

.a-semi-bold {
  font-weight: 600;
}

.a-invisible {
  visibility: hidden;
}

.a-font-reset {
  font-size: initial !important;
  font-weight: initial !important;
}

.a-docraptor-print-table,
.a-docraptor-print-table .a-smart-document-container,
.a-docraptor-print-table .p-datatable-table {
  border: 0;
  width: 100%;
}

.a-docraptor-print-table > thead div {
  justify-content: space-between;
  padding: 0 0.2in;
  color: #000;
  margin-top: -0.2in;
}

.a-disabled {
  opacity: 0.4;
  cursor: default !important;
  color: #d5d5d5;
}
.a-disabled.a-no-action {
  pointer-events: none;
}

.p-datepicker-other-month {
  opacity: 0.6;
}

.p-multiselect-item svg.p-icon {
  pointer-events: none;
}

.a-error {
  font-size: 1rem;
  padding: 0.3rem 0;
  color: #ff4949;
}
.a-error:not([data-is-error=true]) {
  height: 0px;
  padding: 0;
  visibility: hidden;
}

#zohohc-asap-web-launcherbox {
  display: none;
}

.a-smart-multiselect-toggle-panel {
  width: fit-content;
  min-width: 20rem;
}
.a-smart-multiselect-toggle-panel.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0;
}
.a-smart-multiselect-toggle-panel .p-multiselect-close {
  display: none;
}

.a-smart-multiselect-toggle .p-multiselect-label-container {
  display: none;
}

.a-smart-dropdown-grouped .p-dropdown-item {
  padding-left: 2rem !important;
}

.a-page-header-action-buttons {
  display: flex;
  align-items: center;
}
.a-page-header-action-buttons .a-page-header-action-button {
  margin: 0;
  border: 1px solid #616161;
  border-right: 0;
  border-radius: 0;
}
.a-page-header-action-buttons .a-page-header-action-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.a-page-header-action-buttons .a-page-header-action-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #616161;
}
.a-page-header-action-buttons .a-page-header-action-button:hover:not(:last-child) {
  border-right: 0;
}

.a-page-header-action-button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #616161;
  font-weight: 600;
  padding: 0 0.6rem;
  margin: 0 0.6rem;
  height: 2.5rem;
  user-select: none;
  cursor: pointer;
}
.a-page-header-action-button svg:last-child:not(:first-child) {
  margin-left: 0.6rem;
}
.a-page-header-action-button:first-child {
  margin-left: 0;
}
.a-page-header-action-button:last-child {
  margin-right: 0;
}
.a-page-header-action-button:hover:not(.a-disabled) {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #616161;
}
.a-page-header-action-button.a-primary {
  background: #fd0;
  color: #363636;
  border: 1px solid #fd0;
}
.a-page-header-action-button.a-primary:hover {
  background: #ffe284;
  border: 1px solid #ffe284;
}

.a-double-confirmation-checkbox-container {
  display: flex;
  gap: 0.6rem;
  padding: 0.6rem;
  border: 1px solid #616161;
  background-color: #363636;
  color: #d5d5d5;
  border-radius: 0.3rem;
}

.a-line-seperator {
  border-top: 1px solid #616161;
  margin: 0.3rem 0;
}

.ql-editor {
  font-size: 12px;
  font-family: Verdana, Geneva, sans-serif;
  margin-top: -1px;
  padding: 1px 0.2em 0;
  word-wrap: break-word;
  -webkit-nbsp-mode: space;
  -webkit-line-break: after-white-space;
  padding: 16px;
  box-sizing: border-box;
}

.a-green-button, .a-green-button.a-smart-button {
  color: #0f960f;
  background: #cffacf;
  border-color: #cffacf;
}
.a-green-button:not(:disabled):hover, .a-green-button.a-smart-button:not(:disabled):hover {
  color: #ffffff;
  background: #0f960f;
  border-color: #0f960f;
}

.a-red-button, .a-red-button.a-smart-button {
  color: #ff4949;
  background: #ffb2b2;
  border-color: #ffb2b2;
}
.a-red-button:not(:disabled):hover, .a-red-button.a-smart-button:not(:disabled):hover {
  color: #ffffff;
  background: #ff4949;
  border-color: #ff4949;
}

.a-full-width {
  width: 100%;
}

.a-form-element-content:not(:last-child),
.a-form-element-paginator-container:not(:last-child) {
  border-bottom: 1px solid #616161;
}

.a-form-element-paginator-container .p-paginator {
  border: none;
  background: none;
  padding-top: 0;
}

.a-form-element-container {
  width: 100%;
  background: #292929;
  border: 1px solid #616161;
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
.a-form-element-container .a-form-element-header {
  padding: 1.2rem 1.2rem 0 1.2rem;
}
.a-form-element-container .a-form-element-header.a-flex-center {
  justify-content: space-between;
}
.a-form-element-container .a-form-element-content {
  padding: 1.2rem;
}
.a-form-element-container .a-form-element-content:has(+ .a-form-element-footer) {
  border-bottom: none;
}
.a-form-element-container .a-form-element-content .a-form-element-header {
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0 0 0.6rem 0;
}
.a-form-element-container .a-form-element-content .a-form-element-header .a-smart-button {
  text-transform: none;
}
.a-form-element-container .a-form-element-content.a-flex-center {
  justify-content: space-between;
}
.a-form-element-container .a-form-element-content .a-form-element-column-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.a-form-element-container .a-form-element-content .a-form-element-column-container .a-form-element-column {
  width: 47%;
}
.a-form-element-container .a-form-element-content > div:last-of-type {
  padding-bottom: 0;
}
.a-form-element-container .a-form-element-content > div:first-of-type {
  padding-top: 0;
}
.a-form-element-container:first-child {
  margin-top: 0;
}
.a-form-element-container:last-child {
  margin-bottom: 0;
}

.a-form-element-header {
  font-weight: 600;
  font-size: 1.4rem;
  color: #d5d5d5;
}
.a-form-element-header .a-smart-button {
  font-size: 1rem;
}

.a-form-element-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.6rem;
  padding: 0 1.2rem 1.2rem 1.2rem;
}

.a-form-element-input-section {
  width: 100%;
  padding: 0.6rem 0;
}
.a-form-element-input-section label.a-form-element-label {
  display: block;
}
.a-form-element-input-section .a-form-element-label {
  padding-bottom: 0.3rem;
}
.a-form-element-input-section .a-form-element-label.a-extra-padding {
  padding-bottom: 0.6rem;
}
.a-form-element-input-section .a-form-element-sub-label {
  padding: 0.3rem 0 0.6rem 0;
}
.a-form-element-input-section.a-flex, .a-form-element-input-section.a-flex-center, .a-form-element-input-section.a-flex-end {
  justify-content: space-between;
}
.a-form-element-input-section.a-flex .a-form-element-label, .a-form-element-input-section.a-flex-center .a-form-element-label, .a-form-element-input-section.a-flex-end .a-form-element-label {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}
.a-form-element-input-section.a-flex > *, .a-form-element-input-section.a-flex-center > *, .a-form-element-input-section.a-flex-end > * {
  width: 47%;
}
.a-form-element-input-section .a-form-element-multiple-input-row {
  padding: 0;
}

.a-form-element-label {
  font-weight: 600;
  width: 100%;
}
.a-form-element-label:not(.a-red) {
  color: #d5d5d5;
}
.a-form-element-label.a-light {
  font-weight: 500;
}
.a-form-element-label.a-disabled {
  opacity: 0.4;
}

.a-form-element-multiple-input-row {
  display: flex;
  align-items: center;
  padding: 0.6rem 0;
}
.a-form-element-multiple-input-row .a-form-element-multiple-input-row {
  padding: 0;
}
.a-form-element-multiple-input-row .a-form-element-input-section {
  padding: 0 0.6rem;
}
.a-form-element-multiple-input-row .a-form-element-input-section:first-child {
  padding-left: 0;
}
.a-form-element-multiple-input-row .a-form-element-input-section:last-child {
  padding-right: 0;
}
.a-form-element-multiple-input-row:first-child {
  padding-top: 0;
}
.a-form-element-multiple-input-row:last-child {
  padding-bottom: 0;
}

.a-right-input .p-inputnumber input {
  text-align: right;
}

.a-center-input .p-inputnumber input {
  text-align: center;
}

a.a-form-element-value.a-readonly {
  display: block;
}

.a-form-element-value {
  --inline-max-length-width: 4rem;
  position: relative;
  width: 100%;
}
.a-form-element-value:has(.a-form-element-max-length-inline:not(.a-hidden)) .p-inputtext {
  padding-right: var(--inline-max-length-width);
}
.a-form-element-value .a-form-element-max-length-inline {
  position: absolute;
  width: var(--inline-max-length-width);
  text-align: right;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.a-form-element-value .a-form-element-top-right-section {
  position: absolute;
  right: 2px;
  top: -1.5rem;
  display: flex;
}
.a-form-element-value .a-form-element-top-right-section .a-voice-recognition {
  color: #fd0;
  font-size: 1.2rem;
  padding: 0 0.4rem;
  cursor: pointer;
}
.a-form-element-value .a-form-element-top-right-section .a-recording {
  color: #ff4949;
}
.a-form-element-value .a-form-element-max-length {
  font-size: 0.8rem;
}
.a-form-element-value input,
.a-form-element-value textarea,
.a-form-element-value .p-inputnumber,
.a-form-element-value .a-smart-dropdown-wrapper,
.a-form-element-value .p-dropdown,
.a-form-element-value .p-calendar,
.a-form-element-value .p-multiselect {
  width: 100%;
}
.a-form-element-value.a-required input, .a-form-element-value.a-required textarea, .a-form-element-value.a-required .p-dropdown, .a-form-element-value.a-required .p-multiselect {
  border-left: 4px solid #ff4949;
}
.a-form-element-value.a-required input:hover, .a-form-element-value.a-required input:focus, .a-form-element-value.a-required input.p-focus, .a-form-element-value.a-required textarea:hover, .a-form-element-value.a-required textarea:focus, .a-form-element-value.a-required textarea.p-focus, .a-form-element-value.a-required .p-dropdown:hover, .a-form-element-value.a-required .p-dropdown:focus, .a-form-element-value.a-required .p-dropdown.p-focus, .a-form-element-value.a-required .p-multiselect:hover, .a-form-element-value.a-required .p-multiselect:focus, .a-form-element-value.a-required .p-multiselect.p-focus {
  border-left: 4px solid #fd0;
}
.a-form-element-value.a-readonly, .a-form-element-value > .a-readonly {
  font-size: 1.2rem;
  height: 2.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  width: auto;
}
.a-form-element-value.a-readonly.a-form-element-textarea, .a-form-element-value > .a-readonly.a-form-element-textarea {
  overflow: auto;
  white-space: pre-wrap;
}
.a-form-element-value.a-right {
  justify-content: flex-end;
}
.a-form-element-value.a-form-element-textarea {
  --h: calc(var(--height-factor, 1) * 5rem);
  max-height: var(--h);
  height: 100%;
  width: 100%;
}
.a-form-element-value.a-form-element-textarea[data-is-min-height=true] {
  min-height: min(5rem, var(--h));
}
.a-form-element-value.a-form-element-textarea[data-is-min-height=false] {
  min-height: 2.5rem;
}
.a-form-element-value.a-form-element-textarea:has(textarea) {
  height: var(--h);
}
.a-form-element-value.a-form-element-textarea textarea {
  overflow: auto !important;
  height: 100% !important;
}
.a-form-element-value.a-no-height-limit {
  max-height: none;
}
.a-form-element-value.a-inline-input {
  display: flex;
}
.a-form-element-value.a-inline-input .a-smart-toggle {
  height: auto;
}
.a-form-element-value.a-inline-input.a-required input,
.a-form-element-value.a-inline-input.a-required textarea,
.a-form-element-value.a-inline-input.a-required .p-dropdown {
  border: 1px solid #616161;
  border-right: 0;
  border-top: 0;
  border-left: 4px solid #ff4949;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  text-indent: 0.3rem;
}
.a-form-element-value.a-inline-input input,
.a-form-element-value.a-inline-input textarea,
.a-form-element-value.a-inline-input .p-dropdown {
  border: 0;
  border-bottom: 1px solid #616161;
  border-radius: 0;
  background: transparent;
  text-indent: unset;
  padding: 0;
}
.a-form-element-value.a-inline-input input.p-inputtext:enabled:focus,
.a-form-element-value.a-inline-input textarea.p-inputtext:enabled:focus,
.a-form-element-value.a-inline-input .p-dropdown.p-inputtext:enabled:focus {
  box-shadow: none;
  border-bottom: 1px solid #ffee80;
}
.a-form-element-value.a-inline-input .p-dropdown .p-inputtext {
  padding: 0;
  padding-right: 0.9rem;
}
.a-form-element-value.a-inline-input .p-button.p-button-icon-only {
  padding: 0;
}
.a-form-element-value.a-inline-input .p-dropdown-trigger {
  display: none;
}
.a-form-element-value.a-inline-input .p-dropdown-clear-icon {
  right: 0;
}
.a-form-element-value.a-inline-input .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}
.a-form-element-value input[type=color] {
  padding: 0;
  border: none;
  appearance: none;
}
.a-form-element-value input[type=color]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.a-inline-label-border {
  border-bottom: 1px solid transparent;
}

.a-form-element-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.2rem;
}

.a-form-element-preferred-item {
  display: flex;
  align-items: center;
}
.a-form-element-preferred-item svg {
  color: #fd0;
  margin-right: 0.6rem;
  font-size: 0.8rem;
}

.a-form-inline-action-button {
  color: #fd0;
  text-transform: none;
  cursor: pointer;
  user-select: none;
}
.a-form-inline-action-button:hover {
  color: #ffe284;
  text-decoration: underline;
}
.a-form-inline-action-button.a-disabled {
  opacity: 0.4;
  cursor: default;
  color: #d5d5d5;
}
.a-form-inline-action-button.a-disabled:hover {
  text-decoration: none;
}

