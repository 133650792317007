.a-smart-popup.a-form-element-container.a-smart-error-popup,
.a-smart-error-container .a-smart-error {
  width: 50rem;
}

.a-smart-error-container {
  display: flex;
  justify-content: center;
}
.a-smart-error-container .a-smart-error {
  height: fit-content;
}
.a-smart-error-container .a-smart-error .a-form-element-content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.a-smart-error-container .a-smart-error .a-smart-error-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a-smart-error-container .a-smart-error .a-smart-error-action {
  color: #ff4949;
}
.a-smart-error-container .a-smart-error .a-smart-error-action:hover {
  cursor: pointer;
  text-decoration: underline;
}
.a-smart-error-container .a-smart-error-icon {
  color: #ff4949;
}

.a-smart-error-details {
  background-color: #363636;
  padding: 1.2rem 1.8rem 1.2rem 1.2rem;
  position: relative;
  border-radius: 8px;
  margin: 0;
}

.a-smart-error-copy {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.6rem;
}
.a-smart-error-copy:hover {
  color: #d5d5d5;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .a-smart-error-container .a-smart-error {
    width: 100%;
  }
}

