.a-smart-status-container {
  display: flex;
  align-items: center;
}
.a-smart-status-container > * {
  margin: 0.3rem;
}
.a-smart-status-container > *:first-child {
  margin-left: 0;
}
.a-smart-status-container .a-smart-status-info {
  font-size: 1.4rem;
  color: #20a1e7;
}

.a-smart-status-tooltip {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  padding: 0.6rem;
}

.a-smart-status {
  display: flex;
  align-items: center;
  padding: 0 0.6rem;
  border-radius: 2rem;
  width: fit-content;
  color: #292929;
}
.a-smart-status svg {
  font-size: 0.8rem;
  margin-right: 8px;
}

.a-smart-status-default {
  background: #a8a8a8;
}

.a-smart-status-label-default {
  color: #a8a8a8 !important;
}

.a-smart-status-blue {
  background-color: #20a1e7;
  color: #363636;
}

.a-smart-status-yellow {
  background: #fff6bA;
}

.a-smart-status-label-yellow {
  color: #fff6bA !important;
}

.a-smart-status-dark-yellow {
  background: #fd0;
}

.a-smart-status-orange {
  background: #ffd280;
}

.a-smart-status-red {
  background-color: #ff4949;
  color: white;
}

.a-smart-status-dark-orange {
  background: darkorange;
}

.a-smart-status-green {
  background: #cffacf;
}

.a-smart-status-label-green {
  color: #cffacf !important;
}

.a-smart-status-dark-green {
  background: #0f960f;
}

.a-smart-status-skeleton {
  height: 13.3px !important;
}
@media screen and (min-width: 768px) {
  .a-smart-status-skeleton {
    height: 15.2px !important;
  }
}
@media screen and (min-width: 992px) {
  .a-smart-status-skeleton {
    height: 19px !important;
  }
}

