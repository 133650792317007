@import '../../../styles/base/variables';

.a-smart-popup.a-form-element-container.a-smart-error-popup,
.a-smart-error-container .a-smart-error {
  width: 50rem;
}

.a-smart-error-container {
  display: flex;
  justify-content: center;

  .a-smart-error {
    height: fit-content;

    .a-form-element-content {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }

    .a-smart-error-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .a-smart-error-action {
      color: a-get-color(red, normal);

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .a-smart-error-icon {
    color: a-get-color(red, normal);
  }
}

.a-smart-error-details {
  background-color: a-get-color(basic, darker);
  padding: 1.2rem 1.8rem 1.2rem 1.2rem;
  position: relative;
  border-radius: 8px;
  margin: 0;
}

.a-smart-error-copy {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.6rem;

  &:hover {
    color: a-get-color(basic, bright);
    cursor: pointer;
  }
}

@media screen and (max-width: a-get-media(m)) {
  .a-smart-error-container {
    .a-smart-error {
      width: 100%;
    }
  }
}