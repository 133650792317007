@import '../../../styles/base/variables';

.a-smart-status-container {
  display: flex;
  align-items: center;

  &>* {
    margin: 0.3rem;

    &:first-child {
      margin-left: 0;
    }
  }

  .a-smart-status-info {
    font-size: a-get-font-size(xl);
    color: a-get-color(blue, normal)
  }
}

.a-smart-status-tooltip {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  padding: 0.6rem;
}

.a-smart-status {
  display: flex;
  align-items: center;
  padding: 0 0.6rem;
  border-radius: 2rem;
  width: fit-content;
  color: a-get-color(primary, darkest);

  svg {
    font-size: a-get-font-size(s);
    margin-right: 8px;
  }
}

.a-smart-status-default {
  background: a-get-color(primary, brightest);
}

.a-smart-status-label-default {
  color: a-get-color(primary, brightest) !important;
}

.a-smart-status-blue {
  background-color: a-get-color(blue, normal);
  color: a-get-color(primary, darker);
}

.a-smart-status-yellow {
  background: a-get-color(yellow, brighter);
}

.a-smart-status-label-yellow {
  color: a-get-color(yellow, brighter) !important;
}

.a-smart-status-dark-yellow {
  background: a-get-color(yellow, normal);
}

.a-smart-status-orange {
  background: a-get-color(orange, bright);
}

.a-smart-status-red {
  background-color: a-get-color(red, normal);
  color: white;
}

.a-smart-status-dark-orange {
  background: a-get-color(orange, dark);
}

.a-smart-status-green {
  background: a-get-color(green, bright);
}

.a-smart-status-label-green {
  color: a-get-color(green, bright) !important;
}

.a-smart-status-dark-green {
  background: a-get-color(green, normal);
}

.a-smart-status-skeleton {
  $height: 19px;

  height: $height * 0.7 !important;

  @media screen and (min-width: a-get-media(m-min)) {
    height: $height * .8 !important;
  }

  @media screen and (min-width: a-get-media(l-min)) {
    height: $height !important;
  }
}