.a-smart-grid-input {
  display: flex;
  gap: 0.6rem;
  justify-content: space-between;
  padding: 0.3rem;
}
.a-smart-grid-input button {
  all: unset;
}
.a-smart-grid-input button:disabled {
  opacity: 0.4;
  cursor: default;
}
.a-smart-grid-input .a-smart-grid-input-readonly-value {
  width: 100%;
}
.a-smart-grid-input[data-type=text] .a-smart-grid-input-readonly-value {
  border-bottom: 1px solid #292929;
}
.a-smart-grid-input[data-type=text][data-is-edit=false]:hover .a-smart-grid-input-readonly-value {
  border-bottom: 1px solid #fd0;
}
.a-smart-grid-input .a-smart-grid-input-actions-container {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  z-index: 1000;
}
.a-smart-grid-input .a-smart-grid-input-edit-button {
  color: #a4a4a4;
}
.a-smart-grid-input[data-is-edit=false]:not(:disabled):hover {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.3rem;
}
.a-smart-grid-input[data-is-edit=false]:not(:disabled):hover .a-smart-grid-input-edit-button {
  color: #ffffff;
}
.a-smart-grid-input .a-smart-grid-input-action-button {
  padding: 0.15rem 0.3rem;
  border-radius: 0.3rem;
  transition: all 0.2s ease;
}
.a-smart-grid-input .a-smart-grid-input-action-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.a-smart-grid-input .a-smart-grid-input-action-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

