@import '../../../styles/base/variables';

.a-app-content-container:has(.a-main-menu-container.a-collapsed) {
  .a-grid-page-container.a-smart-grid-menu {
    .a-smart-grid-container {
      left: 4.9rem;
      transition: left 0.2s ease;
    }
  }
}

.a-app-content-container:has(.a-main-menu-container) .a-grid-page-container.a-smart-grid-menu .a-smart-grid-container {
  top: 4rem;
  left: 250px;
  height: calc(100% - 4rem);
  transition: left 0.2s ease;
}

.a-grid-page-container.a-smart-grid-menu {
  .a-smart-grid-container {
    position: absolute;
    width: var(--grid-navigation-width, 250px);
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    top: 0;
    left: 0;
    height: 100%;

    .a-smart-grid-search-container {
      width: 100%;
    }

    @media screen and (max-width: a-get-media(m)) {
      & {
        display: none;
      }
    }

    .p-datatable-thead>tr>th.a-smart-grid-first-column-header {
      display: flex;
      align-items: center;
      position: relative;
      padding-right: 3.6rem;

      .a-smart-grid-insert-column-header-button {
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;

        .a-smart-button {
          padding: 0.3rem 0.3rem;
          min-width: unset;
        }
      }
    }
  }
}

.a-grid-page-container:not(.a-smart-grid-menu),
.a-smart-popup-grid.a-stretch {
  .p-datatable {

    .p-datatable-tbody>tr>td:not(.p-selection-column, .p-reorder-column, .a-smart-grid-extendable-column),
    .p-datatable-thead>tr>th:not(.p-selection-column, .p-reorder-column, .a-smart-grid-extendable-column) {
      width: 160px;
      min-width: 160px;
    }
  }
}

.p-datatable {
  .p-datatable-tbody>tr.p-datatable-row-expansion {
    background: a-get-color(primary, darker-up);
  }

  .p-datatable-tbody>tr>td:not(.p-selection-column, .a-smart-grid-extendable-column),
  .p-datatable-thead>tr>th:not(.p-selection-column, .a-smart-grid-extendable-column) {
    &.a-right {
      text-align: right;
    }
  }

  th:not(.a-smart-grid-checkbox-column) .p-column-title {
    width: 100%;
    overflow: hidden;
  }


  .p-column-filter-menu {
    overflow: visible;
  }

  .p-column-filter-menu-button {
    width: 1.8rem;
    height: 1.8rem;

    .pi {
      line-height: unset;
    }
  }
}

.a-smart-grid-container {
  height: 100%;
  background: a-get-color(primary, darkest);
  border: 1px solid a-get-color(primary, bright);
  border-radius: 8px;
  overflow: auto;

  .p-datatable-resizable {

    .p-datatable-thead>tr>th,
    .p-datatable-tfoot>tr>td,
    .p-datatable-tbody>tr>td {
      overflow: visible;
      white-space: unset;
    }
  }

  .p-column-resizer-helper {
    max-height: 100%;
  }

  .a-smart-grid-selection-actions {
    display: none;
    position: absolute;
    left: 0.9rem;
    height: 2.4rem;
    border: 1px solid a-get-color(primary, bright);
    z-index: 1;
    border-radius: 4px;

    top: 50%;
    transform: translateY(-50%);

    .a-smart-grid-selection-action-container {
      border-right: 1px solid a-get-color(primary, bright);

      &:last-child {
        border: none;
      }

      .a-smart-grid-selection-action {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0.6rem;
        font-weight: a-get-font-weight(semibold);
        user-select: none;
        cursor: pointer;
        white-space: nowrap;

        &.a-smart-grid-selection-selected {
          padding-left: 4.1rem;
          padding-right: 0;

          .a-smart-grid-selection-selected-cancel {
            padding: 0.6rem;
            z-index: 100;
          }
        }

        &:hover:not(.a-disabled) {
          background: a-get-color(hover, normal);
        }

        &.a-disabled {
          cursor: default;
          opacity: 0.15;
        }
      }

      .a-smart-grid-selection-more-action svg {
        margin-left: 0.6rem;
      }
    }
  }

  .a-smart-grid-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    .a-smart-grid-search-container {
      width: 33%;
    }

    .a-smart-grid-header-actions {
      display: flex;
      justify-content: flex-end;
      width: 60%;

      .a-smart-grid-header-selector {
        width: 100%;
        max-width: 20rem;
        margin: 0 0.3rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .a-smart-grid-footer {
    font-size: a-get-font-size(s);
    text-align: right;
  }

  &:not(.a-virtual-render) .p-datatable-wrapper .p-virtualscroller .p-datatable-tbody>tr:not(.p-datatable-emptymessage):not(.p-datatable-row-expansion)>td {
    padding: 0 1rem;
  }

  .p-datatable-wrapper {
    overflow: auto;
    flex: 1;

    .p-virtualscroller {
      height: 100% !important;
    }

    table {
      thead {
        background: a-get-color(primary, darkest);
        z-index: 1;
      }
    }
  }

  .p-datatable {
    display: flex;
    flex-direction: column;
    height: 100%;

    .a-smart-grid-highlight .pi-filter-icon {
      color: a-get-color(secondary, normal);
    }

    .p-sortable-column.p-highlight,
    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
      color: a-get-color(basic, brightest);
    }

    .a-smart-grid-icon-info {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }

    .p-datatable-header {
      padding: 0;
      border: 0;
      z-index: 1;
    }

    .p-datatable-tbody>tr,
    &.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
      color: a-get-color(basic, brightest);
    }

    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover,
    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
    &.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
      background: a-get-color(primary, darker-up);
    }

    .p-sortable-column {
      .p-sortable-column-icon {
        margin: 0 0.3rem;
        overflow: visible;
      }

      .p-sortable-column-badge {
        display: none;
      }
    }

    .p-datatable-tbody>tr.a-processed,
    &.p-datatable-hoverable-rows .p-datatable-tbody>tr.a-processed:not(.p-highlight):hover {
      color: a-get-color(basic, dark);
    }

    .p-datatable-tbody>tr.a-disabled td {
      text-decoration: line-through;
    }

    .p-datatable-tbody>tr>td {

      .a-smart-grid-inline-data-value,
      .a-smart-grid-default-template {
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow-wrap: anywhere;

        &:not(.a-no-wrap) {
          white-space: break-spaces;
        }
      }
    }

    .p-datatable-tbody>tr:last-child>td {
      border: 0;
    }

    .p-reorder-column {
      width: 3rem;

      .p-datatable-reorderablerow-handle {
        cursor: grab;
      }
    }

    .a-smart-grid-extendable-column,
    .p-selection-column {
      width: 4rem !important;
      padding-left: 1.5rem !important;

      .p-checkbox,
      .p-checkbox-box {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .a-smart-grid-inline-data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2px 0;

    &:empty {
      display: none;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .a-smart-grid-inline-data-label {
      color: a-get-color(primary, brightest);
      padding-right: 0.3rem;
      white-space: nowrap;
    }

    .a-smart-grid-inline-data-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .a-form-element-value {
      flex: 1;
    }
  }

  .a-smart-grid-flag {
    padding-top: 2px;
  }
}

.a-smart-grid-search-container {
  display: flex;
  gap: 0.6rem;
}

.a-smart-grid-menu-content {
  position: relative;
  width: calc(100% - 250px);

  .a-smart-history-list {
    margin-top: 1.2rem;
  }

  @media screen and (max-width: a-get-media(m)) {
    & {
      width: 100%;
    }
  }
}

.a-smart-grid-column-header-filter-icon {
  color: a-get-color(secondary, normal);
  padding: 0 0.3rem;
}

.a-smart-grid-copy-to-clipboard-column {
  display: flex;
  border: 1px solid transparent;
  border-radius: 4px;

  .a-smart-grid-copy-to-clipboard-value,
  .a-smart-grid-copy-to-clipboard {
    padding: 0.5rem;
    word-break: break-all;
  }

  .a-smart-grid-copy-to-clipboard {
    display: flex;
    align-items: center;
    border-left: 1px solid a-get-color(primary, bright);
    user-select: none;
    cursor: pointer;

    &:hover {
      background: a-get-color(hover, normal);
    }
  }

  &:hover {
    border: 1px solid a-get-color(primary, bright);

    .a-smart-grid-copy-to-clipboard.a-invisible {
      visibility: visible;
    }
  }
}

.a-smart-grid-active-actions-header {
  position: relative;

  tr:first-child>th:not(.p-selection-column) {
    pointer-events: none;

    * {
      // Can't use visibility: hidden because of primereact not so unique id for clipPath. See #DEV-I1613.
      pointer-events: none;
      opacity: 0;
    }
  }

  .a-smart-grid-selection-actions {
    display: flex;
  }
}

.a-smart-grid-checkbox-column {
  position: relative;
  overflow: visible;

  .a-smart-grid-selection-header-spinner {
    color: a-get-color(secondary, normal);
    opacity: 1;
    font-size: a-get-font-size(xl);
  }
}

.a-smart-grid-multiselect-filter-container.p-column-filter-overlay {
  border: 0;

  .p-column-filter-matchmode-dropdown,
  .p-column-filter-buttonbar {
    display: none;
  }

  .p-column-filter-constraint {
    padding: 0;
  }
}

.a-smart-grid-multiselect-filter-panel {
  .p-multiselect-close {
    display: none;
  }
}

.a-smart-grid-overflow {
  .p-datatable-table {
    border-spacing: 0 1px;
  }

  thead {
    position: sticky;
    top: 0;
  }

  &.a-flex {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .p-datatable .p-datatable-thead>tr>th,
  .p-datatable .p-datatable-tbody>tr>td {
    width: 100%;
  }
}

.a-loading-spinner {
  color: a-get-color(secondary, normal);
  font-size: a-get-font-size(xxxl);
}

.a-smart-filter-relative-calendar {
  display: flex;
  gap: 0.6rem;

  .a-form-element-value:has(.p-inputnumber) {
    width: 25%;
    max-width: 25%;
  }

  .a-form-element-value:has(.p-dropdown) {
    width: 37.5%;
    max-width: 37.5%;
  }
}

.a-smart-filter-calendar-container {
  .p-calendar {
    width: 100%;
  }
}

.p-column-filter-overlay:has(.a-smart-filter-calendar-container) {
  min-width: 300px;
}

.a-section-inline-subline {
  .a-inline-subline {
    padding-left: 0.6rem;
  }
}

.a-smart-grid-icon-flag-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;


  .a-smart-grid-icon-flag {
    color: a-get-color(secondary, normal);

    &:hover {
      opacity: 0.8;
      cursor: help;
    }
  }
}

.a-smart-grid-filter-apply-button {
  width: 40%;
  max-width: 100px;

  .a-smart-button {
    width: 100%;
  }
}