.a-password-requirements {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #616161;
  border-radius: 8px;
  gap: 1.2rem;
  padding: 1.2rem;
}
.a-password-requirements .a-form-element-input-section .a-form-element-label {
  padding-bottom: 0;
}
.a-password-requirements .a-password-requirements-icon {
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 0.6rem;
}

