@import '../../../styles/base/variables';

.a-password-requirements {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid a-get-color(primary, bright);
  border-radius: 8px;
  gap: 1.2rem;
  padding: 1.2rem;

  .a-form-element-input-section .a-form-element-label {
    padding-bottom: 0;
  }

  .a-password-requirements-icon {
    font-size: a-get-font-size(l);
    font-weight: a-get-font-weight(semibold);
    margin-right: 0.6rem;
  }
}